import {allEnv} from 'next-runtime-env';
import {getPendo} from '../../globals';
import {isServerSide} from '../../is-server-side';
import {AnyAnalyticsEvent, PendoContext} from '../types';
import {transformProperties} from './helpers/transform-properties';

const {NEXT_PUBLIC_DD_ENV} = allEnv<IPublicEnv>();

const PENDO_PREFIX = '3d_';

export function trackEvent({event, context}: AnyAnalyticsEvent): void {
  if (isServerSide()) {
    return;
  }

  const pendo = getPendo();
  if (!pendo?.track) {
    return;
  }

  const prefix = event.startsWith(PENDO_PREFIX) ? '' : PENDO_PREFIX;
  const eventName = `${prefix}${event}`;

  const pendoContext = transformProperties<PendoContext>({
    environment: NEXT_PUBLIC_DD_ENV,
    releaseVersion: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
    ...context,
  });

  pendo.track(eventName, pendoContext);
}
